import { useMutation, useQuery } from '@apollo/client';
import {
	RecentSearchesQueryQuery,
	SearchSuggestionsQueryQuery,
	TopSearchQueriesQueryQuery,
} from '@apps/www/src/__generated__/graphql';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import SVColorPickerOverlay from '@apps/www/src/www/overlays/SVColorPickerOverlay';
import DeleteRecentSearchMutation from '@apps/www/src/www/queries/DeleteRecentSearchMutation';
import RecentSearchesQuery from '@apps/www/src/www/queries/RecentSearchesQuery';
import SearchSuggestionsQuery from '@apps/www/src/www/queries/SearchSuggestionsQuery';
import TopSearchQueriesQuery from '@apps/www/src/www/queries/TopSearchQueriesQuery';
import SVA from '@pkgs/shared-client/components/SVA';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVImage from '@pkgs/shared-client/components/SVImage';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVModal from '@pkgs/shared-client/components/SVModal';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconCloseSearchSVG from '@pkgs/shared-client/img/icon-close-search-inlined.svg';
import IconNavbarSearchSVG from '@pkgs/shared-client/img/navbar-search-icon-inlined.svg';
import ImagePalletColor from '@pkgs/shared-client/img/search-palet-color.png';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

type InputProps = {
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	isOnFocus: boolean;
	onFocus?: () => void;
	onClear: undefined | ((e: React.UIEvent) => void);
	onSelectSuggestion?: (suggestion: string) => void;
};

type UserSuggestion = ArrayElement<SearchSuggestionsQueryQuery['searchSuggestions']['users']> & {
	isUser: true;
};

const MIN_QUERY_LENGTH = 2 as const;
export const SUPPORTED_TYPES = ['items', 'users'] as const;

const Input = ({
	value,
	onChange,
	onKeyDown,
	isOnFocus,
	onClear,
	onFocus,
	onSelectSuggestion,
	...props
}: InputProps) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const handleOpenSearchByColor = useEventCallback(() => {
		SVModal.open(SVColorPickerOverlay);
		inputRef.current?.blur();
	});

	useEffect(() => {
		if (isOnFocus && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isOnFocus]);

	const handleKeyDown = useEventCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			inputRef.current?.blur();
		}
		onKeyDown(e);
	});

	const handleFocus = useEventCallback(() => {
		onFocus?.();
	});

	return (
		<div
			className={clsx(
				'duration-slide -md:hidden text-primary relative z-10 flex h-[36px] max-w-[30vw] cursor-text space-x-2 rounded-xl bg-gray-900 px-2 py-2 transition-all ease-in-out',
				isOnFocus ? 'w-[720px]' : 'w-72',
			)}
			{...props}
		>
			<div
				className={clsx(
					'duration-slide flex w-5 items-center justify-center text-gray-500 transition-all ease-in-out',
					isOnFocus ? 'hidden' : 'block ',
				)}
			>
				<IconNavbarSearchSVG className="h-5 w-5 min-w-5" />
			</div>
			<input
				type="text"
				value={value}
				onChange={onChange}
				onKeyDown={handleKeyDown}
				onFocus={handleFocus}
				placeholder="Find new inspiration"
				className="w-full border-none bg-transparent p-0 outline-none focus:outline-none focus:ring-0"
				ref={inputRef}
				required
			/>
			<div
				className={clsx(
					'duration-over transition-opacity ease-in-out',
					value.length > 0 ? 'opacity-100' : 'pointer-events-none opacity-0',
				)}
			>
				<SVIconButton
					src={IconCloseSearchSVG}
					onClick={(e) => {
						e.stopPropagation();
						if (onClear) onClear(e);
					}}
					className="h-5 w-5 text-gray-400"
					disableHover
				/>
			</div>
			<div
				onClick={handleOpenSearchByColor}
				className={clsx(
					'duration-over flex min-w-4 cursor-pointer items-center justify-center transition-all ease-in-out',
					isOnFocus ? 'flex opacity-100' : 'pointer-events-none hidden',
				)}
			>
				<SVImage src={ImagePalletColor} alt="Search by color" className="h-4 w-4" />
			</div>
		</div>
	);
};

const SectionTitle = ({ title, className }: { title: string; className?: string }) => (
	<div className={clsx('flex items-center pb-3 text-sm text-gray-300', className)}>{title}</div>
);

const RecentSearchItem = ({
	search,
	handleClear,
}: {
	search: RecentSearchesQueryQuery['recentSearches'][number];
	handleClear: (id: RecentSearchesQueryQuery['recentSearches'][number]['_id']) => void;
}) => (
	<SVLink
		className="group mr-0.5 flex max-w-40 items-center rounded-full border border-gray-700 px-3 py-2 text-sm"
		to={`/search?q=${encodeURIComponent(search.query)}`}
	>
		<div className="flex items-center space-x-2">
			<div className="w-full truncate text-xs text-gray-400">{search.query}</div>
			<SVIconButton
				src={IconCloseSearchSVG}
				onClick={(e) => {
					handleClear(search._id);
					e.preventDefault();
					e.stopPropagation();
				}}
				className="text-gray-600"
				iconClassName="h-4 w-4 min-w-4 min-h-4"
				disableHover
			/>
		</div>
	</SVLink>
);

const PopularSearchItem = ({
	search,
}: {
	search: TopSearchQueriesQueryQuery['topSearchQueries'][number];
}) => (
	<SVLink
		className="group flex h-12 items-center justify-start space-x-2 rounded-lg bg-gray-800 p-1"
		to={`/search?q=${encodeURIComponent(search.query)}`}
	>
		<div className="relative h-10 w-10 overflow-hidden rounded">
			<div className="absolute inset-0 overflow-hidden">
				<div
					className="duration-over absolute inset-0 scale-[300%] bg-cover bg-center bg-no-repeat opacity-100 transition-all ease-out group-hover:scale-100 group-hover:opacity-80"
					style={{
						backgroundColor: search.asset.colors[0]?.color,
						backgroundImage: `url('${search.asset.image.thumbnail}')`,
					}}
				/>
			</div>
		</div>

		<button className="w-fit truncate text-xs text-gray-300">{search.query}</button>
	</SVLink>
);

const SearchContent = ({
	recentSearches,
	popularSearches,
	isVisible,
	searchQuery,
	selectedIndex,
	onSelectSuggestion,
	onItemHover,
	hoveredItemIndex,
	searchSuggestions,
}: {
	recentSearches: RecentSearchesQueryQuery['recentSearches'];
	popularSearches: TopSearchQueriesQueryQuery['topSearchQueries'];
	isVisible: boolean;
	searchQuery: string;
	selectedIndex: number;
	onSelectSuggestion: (suggestion: string | UserSuggestion) => void;
	onItemHover: (index: number | null) => void;
	hoveredItemIndex: number | null;
	searchSuggestions?: SearchSuggestionsQueryQuery['searchSuggestions'];
}) => {
	const [deleteRecentSearch] = useMutation(DeleteRecentSearchMutation);
	const [recentSearchesToShow, setRecentSearchesToShow] = useState(recentSearches || []);
	const contentRef = useRef<HTMLDivElement>(null);
	const [contentHeight, setContentHeight] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (contentRef.current && isVisible) {
			const newHeight = contentRef.current.scrollHeight;
			setContentHeight(newHeight);
		}
	}, [
		isVisible,
		searchQuery,
		recentSearches,
		popularSearches,
		recentSearchesToShow,
		searchSuggestions,
	]);

	useEffect(() => {
		setRecentSearchesToShow(recentSearches || []);
	}, [recentSearches]);

	const handleClearRecentSearches = useEventCallback(async (id: string) => {
		await deleteRecentSearch({
			variables: {
				input: {
					searchID: id,
				},
			},
		});

		setRecentSearchesToShow((prev) => prev.filter((search) => search._id !== id));
	});

	const textSuggestions =
		searchQuery.trim().length > 0 && searchSuggestions
			? searchSuggestions.queries.map((suggestion) => suggestion.query)
			: [];

	const userSuggestions: UserSuggestion[] =
		searchQuery.trim().length > 0 && searchSuggestions
			? searchSuggestions.users.map((user) => ({
					...user,
					isUser: true,
			  }))
			: [];
	const allSuggestions = [...textSuggestions, ...userSuggestions];

	const isShowingSuggestions = searchQuery.trim().length > 0;

	if (!isVisible) {
		return null;
	}

	return (
		<div
			className={clsx(
				'duration-slide absolute z-20 mt-1 min-w-full max-w-[40vw] overflow-hidden rounded-xl bg-gray-900 shadow-lg transition-all ease-in-out',
				isVisible
					? 'translate-y-0 transform opacity-100'
					: 'pointer-events-none -translate-y-2 transform opacity-0',
			)}
			style={{
				height: contentHeight !== undefined ? `${contentHeight}px` : 'auto',
				top: '100%',
				left: 0,
				width: '100%',
			}}
		>
			<div ref={contentRef}>
				{isShowingSuggestions ? (
					<div>
						{allSuggestions.length > 0 ? (
							<div className="w-full space-y-0.5 p-2">
								{textSuggestions.map((suggestion, index) => {
									const globalIndex = index + userSuggestions.length;
									return (
										<SearchSuggestionItem
											key={suggestion}
											suggestion={suggestion}
											searchQuery={searchQuery}
											isSelected={
												globalIndex === selectedIndex &&
												hoveredItemIndex === null
											}
											onClick={() => onSelectSuggestion(suggestion)}
											onMouseEnter={() => onItemHover(globalIndex)}
											onMouseLeave={() => onItemHover(null)}
											isHovered={hoveredItemIndex === globalIndex}
										/>
									);
								})}
								{userSuggestions.map((suggestion, index) => (
									<SearchSuggestionUser
										key={suggestion.username}
										suggestion={suggestion}
										isSelected={
											index === selectedIndex && hoveredItemIndex === null
										}
										onClick={() => onSelectSuggestion(suggestion)}
										onMouseEnter={() => onItemHover(index)}
										onMouseLeave={() => onItemHover(null)}
										isHovered={hoveredItemIndex === index}
									/>
								))}
							</div>
						) : null}
					</div>
				) : (
					<div
						className={
							recentSearchesToShow.length > 0 || popularSearches?.length > 0
								? 'p-6'
								: ''
						}
					>
						{recentSearchesToShow.length > 0 && (
							<>
								<SectionTitle title="Recents" />
								<div className="no-scrollbar flex gap-1 overflow-x-auto">
									{recentSearchesToShow.map((search) => (
										<RecentSearchItem
											key={search._id}
											search={search}
											handleClear={handleClearRecentSearches}
										/>
									))}
								</div>
							</>
						)}
						{popularSearches?.length > 0 && (
							<>
								<SectionTitle
									title="Popular"
									className={clsx(recentSearchesToShow.length > 0 ? 'mt-6' : '')}
								/>
								<div className="grid grid-cols-2 gap-3 lg:grid-cols-3 xl:grid-cols-4">
									{popularSearches.slice(0, 8).map((popularSearch) => (
										<PopularSearchItem
											key={popularSearch._id}
											search={popularSearch}
										/>
									))}
								</div>
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const SearchSuggestionItem = ({
	suggestion,
	searchQuery,
	isSelected,
	onClick,
	onMouseEnter,
	onMouseLeave,
	isHovered,
}: {
	suggestion: string;
	searchQuery: string;
	isSelected?: boolean;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	isHovered?: boolean;
}) => {
	const query = searchQuery.toLowerCase();
	const suggestionLower = suggestion.toLowerCase();
	let index = -1;
	let matchLength = 0;

	if (query.endsWith(' ')) {
		const queryWithoutTrailingSpace = query.trimEnd();
		if (suggestionLower.startsWith(queryWithoutTrailingSpace + ' ')) {
			index = 0;
			matchLength = queryWithoutTrailingSpace.length + 1;
		}
	} else {
		index = suggestionLower.indexOf(query);
		matchLength = query.length;
	}

	// Apply styles based on selection or hover
	const isHighlighted = isSelected || isHovered;

	return (
		<SVA
			Component={SVLink}
			key={suggestion}
			className={clsx(
				'flex w-full items-center rounded-lg p-1 text-gray-500 transition-colors hover:bg-gray-800',
				isHighlighted && 'bg-gray-800 text-white',
			)}
			to={`/search?q=${encodeURIComponent(suggestion)}`}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<IconNavbarSearchSVG className="ml-1.5 mr-3 h-4 w-4 text-gray-500" />
			{index === -1 ? (
				<span className="whitespace-pre">{suggestion}</span>
			) : (
				<div className="whitespace-pre">
					<span className={clsx('text-gray-500', isHighlighted && 'text-gray-300')}>
						{suggestion.substring(0, index)}
					</span>
					<span className={clsx('text-primary', isHighlighted && 'text-white')}>
						{suggestion.substring(index, index + matchLength)}
					</span>
					<span className={clsx('text-gray-500', isHighlighted && 'text-gray-300')}>
						{suggestion.substring(index + matchLength)}
					</span>
				</div>
			)}
		</SVA>
	);
};

const SearchSuggestionUser = ({
	suggestion,
	isSelected,
	onClick,
	onMouseEnter,
	onMouseLeave,
	isHovered,
}: {
	suggestion: UserSuggestion;
	isSelected?: boolean;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	isHovered?: boolean;
}) => {
	// Apply styles based on selection or hover
	const isHighlighted = isSelected || isHovered;

	return (
		<SVA
			Component={SVLink}
			key={suggestion.username}
			className={clsx(
				'flex w-full items-center justify-between rounded-lg p-2 text-gray-300 transition-colors hover:bg-gray-800 hover:text-white',
				isHighlighted && 'bg-gray-800 text-white',
			)}
			to={suggestion.url}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className="flex items-center space-x-3">
				<SVImage
					src={suggestion.avatarURL}
					alt={suggestion.name}
					className="border-primary h-5 w-5 rounded-full border border-opacity-50"
				/>
				<div className="flex flex-col">
					<div className="text-sm ">{suggestion.name}</div>
					<div className="text-xs text-gray-500">@{suggestion.username}</div>
				</div>
			</div>
		</SVA>
	);
};

const _NavSearchContainer = () => {
	const router = useRouter();
	const [searchInputQuery, setSearchInputQuery] = useUIState(UIStateKeys.SEARCH_INPUT_QUERY);
	const { q: queryParam, type: typeParam } = router.query;
	const [isOnFocus, setIsOnFocus] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null);

	const currentType =
		typeof typeParam === 'string' &&
		SUPPORTED_TYPES.includes(typeParam as (typeof SUPPORTED_TYPES)[number])
			? (typeParam as (typeof SUPPORTED_TYPES)[number])
			: 'items';

	// Recent searches query
	const recentSearchesQuery = useQuery(RecentSearchesQuery);

	//  Popular queries
	const topSearchQueriesQuery = useQuery(TopSearchQueriesQuery);

	// Search suggestions query
	// TODO: IMPORTANT! Throttle these requests to 1 per 200ms
	const searchSuggestionsQuery = useQuery(SearchSuggestionsQuery, {
		variables: {
			query: searchInputQuery || '',
		},
		skip: !Boolean(searchInputQuery.trim()),
	});

	// Get suggestions for keyboard navigation
	const getUserSuggestions = () => {
		if (!searchSuggestionsQuery.data?.searchSuggestions || !searchInputQuery.trim().length)
			return [];
		return searchSuggestionsQuery.data.searchSuggestions.users.map((user) => ({
			...user,
			isUser: true as const,
		}));
	};

	const getTextSuggestions = () => {
		if (!searchSuggestionsQuery.data?.searchSuggestions || !searchInputQuery.trim().length)
			return [];
		return searchSuggestionsQuery.data.searchSuggestions.queries.map(
			(suggestion) => suggestion.query,
		);
	};

	const userSuggestions = getUserSuggestions();
	const textSuggestions = getTextSuggestions();
	const allSuggestions = [...textSuggestions, ...userSuggestions];

	useEffect(() => {
		if (router.pathname === '/search') {
			if (
				queryParam &&
				typeof queryParam === 'string' &&
				queryParam.length > MIN_QUERY_LENGTH
			) {
				setSearchInputQuery(queryParam);
				setIsOnFocus(false);
				return;
			}

			setIsOnFocus(true);
			return;
		}

		setSearchInputQuery('');
		setIsOnFocus(false);
	}, [queryParam, setSearchInputQuery, router.pathname]);

	useEffect(() => {
		// Reset selected index when input changes
		setSelectedIndex(-1);
		setHoveredItemIndex(null);
	}, [searchInputQuery]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
				setIsOnFocus(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleSearchChange = useEventCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInputQuery(e.target.value);
	});

	const navigateToSearch = useEventCallback((query: string) => {
		if (query.trim().length > MIN_QUERY_LENGTH) {
			setSearchInputQuery(query.trim());
			setIsOnFocus(false);

			router.replace(
				{
					pathname: '/search',
					query: {
						q: query.trim(),
						...(currentType !== 'items' && { type: currentType }),
					},
				},
				undefined,
				{ shallow: true },
			);
		} else if (router.pathname === '/search') {
			router.replace({
				pathname: '/search',
			});
		}
	});

	const handleSelectSuggestion = useEventCallback((suggestion: string | UserSuggestion) => {
		if (typeof suggestion === 'string') {
			navigateToSearch(suggestion);
		} else {
			// Navigate to user profile
			router.push(suggestion.url);
			setIsOnFocus(false);
		}
	});

	const handleKeyDown = useEventCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		// Keyboard navigation logic
		if (allSuggestions.length > 0 && isOnFocus) {
			switch (e.key) {
				case 'ArrowDown':
					e.preventDefault();
					setSelectedIndex((prev) => (prev < allSuggestions.length - 1 ? prev + 1 : 0));
					setHoveredItemIndex(null);
					break;
				case 'ArrowUp':
					e.preventDefault();
					setSelectedIndex((prev) => (prev > 0 ? prev - 1 : allSuggestions.length - 1));
					setHoveredItemIndex(null);
					break;
				case 'Tab':
					e.preventDefault();
					if (selectedIndex >= 0 && selectedIndex < allSuggestions.length) {
						handleSelectSuggestion(allSuggestions[selectedIndex]);
					}
					break;
				case 'Enter':
					e.preventDefault();
					if (selectedIndex >= 0 && selectedIndex < allSuggestions.length) {
						handleSelectSuggestion(allSuggestions[selectedIndex]);
					} else {
						navigateToSearch(searchInputQuery);
					}
					break;
				case 'Escape':
					e.preventDefault();
					setIsOnFocus(false);
					break;
			}
		} else if (e.key === 'Enter') {
			e.preventDefault();
			navigateToSearch(searchInputQuery);
		}
	});

	const handleClearSearch = useEventCallback((e: React.UIEvent) => {
		if (e) {
			e.stopPropagation();
		}

		setSearchInputQuery('');

		if (router.pathname === '/search') {
			router.replace({
				pathname: '/explore',
				query: undefined,
			});
			return;
		}

		setIsOnFocus(false);
	});

	const handleContainerClick = useEventCallback(() => {
		setIsOnFocus(true);
	});

	const handleItemHover = useEventCallback((index: number | null) => {
		setHoveredItemIndex(index);
	});

	const shouldShowContent = isOnFocus;

	return (
		<div ref={containerRef} onClick={handleContainerClick} className="-md:hidden relative">
			<Input
				value={searchInputQuery}
				onChange={handleSearchChange}
				onKeyDown={handleKeyDown}
				isOnFocus={isOnFocus}
				onClear={Boolean(searchInputQuery) ? (e) => handleClearSearch(e) : undefined}
				onFocus={() => setIsOnFocus(true)}
				onSelectSuggestion={(suggestion) => handleSelectSuggestion(suggestion)}
			/>

			<SearchContent
				recentSearches={recentSearchesQuery.data?.recentSearches || []}
				popularSearches={topSearchQueriesQuery.data?.topSearchQueries || []}
				isVisible={shouldShowContent}
				searchQuery={searchInputQuery}
				selectedIndex={selectedIndex}
				onSelectSuggestion={handleSelectSuggestion}
				onItemHover={handleItemHover}
				hoveredItemIndex={hoveredItemIndex}
				searchSuggestions={searchSuggestionsQuery.data?.searchSuggestions}
			/>
		</div>
	);
};

const SVNavSearchContainer = () => {
	const isLoggedIn = useIsLoggedIn();

	if (!isLoggedIn) {
		return null;
	}

	return <_NavSearchContainer />;
};

export default SVNavSearchContainer;
